import React, { useEffect } from "react"
import "../styles/index.scss"
import Header from "./header"
import Banner from "./banner"
import PastEventsBanner from "./past-events-banner"
import Footer from "./footer"

const HrbfPageLayout = props => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = window.location.search
      if (!params) {
        const storedParams = sessionStorage.getItem("params")
        if (storedParams) {
          window.history.replaceState(
            {},
            "",
            window.location.href + storedParams
          )
        }
        return
      }
      sessionStorage.setItem("params", params)
    }
  }, [])

  return (
    <>
      <div className="main_wrap">
        <div className="container">
          {props?.bannerBackground?.file?.url && (
            <div className="homepage_header">
              <img
                src={props?.bannerBackground?.file?.url}
                className="banner_background"
              />
              <div className="banner_data">
                <Header
                  headerbtn={props.headerbtn}
                  signUpUrl={props.signUpUrl}
                  registrationUrl={props.registrationUrl}
                  watchOnDemandUrl={props.watchOnDemandUrl}
                  transparentHeader={props?.transparentHeader}
                  scrollPosition={props?.scroll}
                  color="white"
                  buttonBgColor="green"
                />
                <Banner
                  title={props.title}
                  bannerLogo={props.logo}
                  date={props.date}
                  bannerImageLeft={props?.bannerImageLeft}
                  bannerImageLeftMobile={props?.bannerImageLeftMobile}
                  bannerImageRight={props?.bannerImageRight}
                  bannerImageRightMobile={props?.bannerImageRightMobile}
                  headerbtn={props.headerbtn}
                  registrationUrl={props.registrationUrl}
                  watchOnDemandUrl={props.watchOnDemandUrl}
                  transparentHeader={props.transparentHeader}
                />
              </div>
            </div>
          )}
          {!props?.bannerBackground?.file?.url && (
            <>
              <Header
                headerbtn={props.headerbtn}
                registrationUrl={props.registrationUrl}
                watchOnDemandUrl={props.watchOnDemandUrl}
                transparentHeader={props?.transparentHeader}
                scrollPosition={props?.scroll}
                color="white"
                bgColor="black"
              />
              <PastEventsBanner
                title={props.title}
                bannerLogo={props.logo}
                date={props.date}
                bannerImageLeft={props?.bannerImageLeft}
                bannerImageLeftMobile={props?.bannerImageLeftMobile}
                bannerImageRight={props?.bannerImageRight}
                bannerImageRightMobile={props?.bannerImageRightMobile}
                headerbtn={props.headerbtn}
                registrationUrl={props.registrationUrl}
                watchOnDemandUrl={props.watchOnDemandUrl}
                transparentHeader={props.transparentHeader}
              />
            </>
          )}

          {props.children}
          <Footer />
        </div>
      </div>
    </>
  )
}

export default HrbfPageLayout
