import React, { useEffect, useState } from "react"
const PastEventsBanner = props => {
  const [windowWidth, setWindowWidth] = useState(() => {
    if (typeof window !== "undefined") return window.innerWidth
  })
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth)
    })
    return () => {
      window.removeEventListener("resize", () => {})
    }
  }, [])

  return (
    <div
      className={
        props.transparentHeader || props.bannerLogo
          ? "banner_main3 banner_main_london"
          : "banner_main3"
      }
    >
      <div
        className={
          props.transparentHeader || props.bannerLogo
            ? "banner_lf1_img banner_london_l_img"
            : "banner_lf1_img"
        }
      >
        {windowWidth &&
          props?.bannerImageLeftMobile &&
          props?.bannerImageLeft && (
            <img
              src={
                Number(windowWidth) < 768
                  ? props?.bannerImageLeftMobile?.file?.url
                  : props?.bannerImageLeft?.file?.url
              }
              alt="eventbannerleft"
            />
          )}
      </div>
      <div
        className={
          props.transparentHeader || props.bannerLogo
            ? "banner_ct_data banner_london_m_img"
            : "banner_ct_data"
        }
      >
        {props?.bannerLogo ? (
          <img src={props.bannerLogo.file.url} alt="banner"></img>
        ) : (
          <h1 className="banner_title">{props.title}</h1>
        )}
        {props?.bannerLogo ? (
          <></>
        ) : (
          <p className="banner_date">{props.date}</p>
        )}
        {/* <div className="banner_btn">
          {(props?.registrationUrl || props?.watchOnDemandUrl) && (
            <a
              target="_blank"
              rel="noreferrer"
              href={
                props?.headerbtn === "WATCH ON DEMAND"
                  ? props?.watchOnDemandUrl
                  : props?.registrationUrl
              }
            >
              {props?.headerbtn}
            </a>
          )}
        </div> */}
      </div>
      <div
        className={
          props.transparentHeader || props.bannerLogo
            ? "banner_rt1_img banner_london_r_img"
            : "banner_rt1_img"
        }
      >
        {windowWidth &&
          props?.bannerImageRightMobile &&
          props?.bannerImageRight && (
            <img
              src={
                windowWidth < 768
                  ? props?.bannerImageRightMobile?.file?.url
                  : props?.bannerImageRight?.file?.url
              }
              alt="eventbannerright"
            />
          )}
      </div>
    </div>
  )
}
export default PastEventsBanner
